<template>
<h1 class="error-text" >Something went wrong</h1>
</template>

<script>
export default {
  name: "Error",

}
</script>

<style scoped>
.error-text{
  text-align: center;
  margin-top: 20%;
}
</style>